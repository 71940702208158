import {DateTime, Duration} from "luxon";


export function utcOffsetSecondsToJsDate(offsetSeconds: number) {
  return DateTime
    // set 0 at utc
    .fromObject({hour: 0, minute: 0, second: 0}, {zone: 'utc'})
    // get the difference
    .plus({second: offsetSeconds})
    // convert to Js Date
    .toJSDate();
}

export function jsDateToUtcOffsetSeconds(date: Date) {
  const utcIsoTime = DateTime.fromJSDate(date)
    // set time zone as UTC and clear milliseconds
    .toUTC().set({millisecond: 0})
    // parse to ISO time with no Z and no milliseconds
    .toISOTime({
      includeOffset: false,
      suppressMilliseconds: true
    }) ?? '';

  // parse utcIsoTime to milliseconds and then to seconds
  return Duration.fromISOTime(utcIsoTime).toMillis() / 1000;
}
