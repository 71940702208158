import { Component, OnInit } from '@angular/core';
import {AppStateService} from "@cat2/legacy-meta-cat";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'departments'
  constructor(
    private cat2AppState: AppStateService
  ) {
  }

  ngOnInit() {
    this.cat2AppState.setValue({
      appIcon: 'assets/icon.svg',
      appName: 'Departments',
      navBarItems: [
        {
          label: 'Home',
          icon: 'home',
          route: '',
          children: [],
          iconType: 'mat-icon'
        }
      ]
    })
  }
}
