import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {
  API_OVERRIDE,
  BYPASS_CAT2_SPA_CONFIGURATION,
  LegacyMetaCatDialogModule,
  Cat2AppShellModule,
  Cat2DataGridModule,
  Cat2FormModule,
  MOCK_API,
} from '@cat2/legacy-meta-cat';
import {OAuthModule} from 'angular-oauth2-oidc';
import {environment} from 'src/environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GraphQLModule} from './graphql.module';
import {MaterialModule} from '@shared/material/material.module';
import {HomePageComponent} from "@pages/home-page/home-page.component";
import {
  DepartmentGridComponent,
  TimeAdjustmentDialogComponent
} from '@features/department-grid/department-grid.component';
import {SpanGridComponent} from "@features/span-grid/span-grid.component";

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    DepartmentGridComponent,
    SpanGridComponent,
    TimeAdjustmentDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    Cat2AppShellModule,
    MaterialModule,
    GraphQLModule,
    HttpClientModule,
    Cat2DataGridModule,
    Cat2FormModule,
    LegacyMetaCatDialogModule
  ],
  providers: [
    {provide: API_OVERRIDE, useValue: environment.url},
    {provide: MOCK_API, useValue: environment.mockApi},
    {provide: BYPASS_CAT2_SPA_CONFIGURATION, useValue: environment.bypassConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
