import {Component, OnInit} from '@angular/core';
import {
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  MakeDateProperty,
  MakeNumberProperty,
  MakeSelectProperty,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {SelectOption} from "@cat2/legacy-meta-cat/lib/shared/metadata/select-option";
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {SpanClientService} from "@core/services/span-client.service";
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";

@Component({
  selector: 'app-span-grid',
  templateUrl: './span-grid.component.html',
  styleUrls: ['./span-grid.component.scss']
})
export class SpanGridComponent implements OnInit {
  classificationOptions: SelectOption[] = [
    {
      value: 'EXCLUSION',
      display: 'Exclusion'
    }, {
      value: 'SHIFT',
      display: 'Shift'
    }
  ];
  metadata: ModelMetadata | null = null;
  dataSource?: Cat2CustomStore;

  constructor(
    private _client: SpanClientService,
    private _process: ClientProcessingService
  ) {
  }

  ngOnInit(): void {
    this._client.departmentName.subscribe(department => {
      this.createMetadata(department?.description ?? '');
      this.loadDataSource();
    })
  }

  createMetadata(department: string) {
    this.metadata = new ModelMetadataBuilder()
      //properties
      .addProperties([
        MakeStringProperty('__id', 'GUID #'),
        MakeStringProperty('id', 'ID'),
        MakeStringProperty('description', 'Description'),
        MakeDateProperty('dayStartTime', 'Day Start Time', "time"),
        MakeDateProperty('dayEndTime', 'Day End Time', "time"),
        MakeNumberProperty('dayStartOffset', 'Day Start Offset'),
        MakeNumberProperty('duration', 'Duration'),
        MakeSelectProperty('classification', 'Classification', this.classificationOptions),
        MakeNumberProperty('departmentStartOffset', 'Department Start Offset')
      ])
      //grid configuration
      .setGridAddMode('dialog')
      .setGridEditMode('form')
      .addGridTitle(department + ' spans')
      .addGridColumn('id')
      .addGridColumn('description').makeFieldRequired('description')
      .addGridColumn('dayStartTime').makeFieldRequired('dayStartTime')
      .addGridColumn('dayEndTime').makeFieldRequired('dayEndTime')
      .addGridColumn('classification').makeFieldRequired('classification')
      .setFocusedRowEnabled(false)
      //form configuration
      .addFormTitle('Add/Edit Span')
      .addPropertyToFormRow('id', 0)
      .addPropertyToFormRow('description', 0)
      .addPropertyToFormRow('dayStartTime', 1)
      .addPropertyToFormRow('dayEndTime', 1)
      .addPropertyToFormRow('classification', 2)
      .create();
  }

  loadDataSource() {
    this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    );
  }
}
