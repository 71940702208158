import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {
  CreateDepartmentGQL,
  CreateDepartmentInput,
  DeleteDepartmentGQL, DepartmentFilterInput,
  DepartmentGQL, QueryDepartmentArgs,
  UpdateDepartmentGQL,
  UpdateDepartmentInput
} from "../../graphql/graphql";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";
import {DateTime} from "luxon";
import {jsDateToUtcOffsetSeconds, utcOffsetSecondsToJsDate} from "@core/helpers/parse-offset";
import {PaginationService} from "@core/services/pagination.service";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(
    private departmentGQL: DepartmentGQL,
    private createDepartmentGQL: CreateDepartmentGQL,
    private updateDepartmentGQL: UpdateDepartmentGQL,
    private deleteDepartmentGQL: DeleteDepartmentGQL,
    private paginationService: PaginationService
  ) {
  }

  readAllRecords(): Observable<Payload<any>> {
    const executionStart = new Date();
    const args: QueryDepartmentArgs = {
      order: undefined,
      skip: this.paginationService.currentPage * this.paginationService.pageSize,
      take: this.paginationService.pageSize
    };
    if (this.paginationService.order && this.paginationService.column != '') {
      switch (this.paginationService.column) {
        case 'description':
          args.order = [{description: this.paginationService.order}];
          break;
        case 'dayStartTime':
          args.order = [{dayStartOffSet: this.paginationService.order}];
          break;
        case 'id':
          args.order = [{id: this.paginationService.order}];
          break;
      }

    }
    return this.departmentGQL.fetch(args, {fetchPolicy: "no-cache"})
      .pipe(
        map(res => this.toPayload(res.data.department?.items ?? [], executionStart, res.data.department?.totalCount!)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord')
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: UpdateDepartmentInput = {
      guid: userInput.guid,
      setDayStartOffSet: {value: jsDateToUtcOffsetSeconds(userInput.dayStartTime)},
      setDescription: {value: userInput.description},
      setId: {value: userInput.id}
    };
    return this.updateDepartmentGQL.mutate({input: parsedInput})
      .pipe(
        map((res: any) => this.toPayload(res.data.updateDepartment, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString()))
        ));
  }

  createClass(item: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: CreateDepartmentInput = {
      id: item.id,
      description: item.description,
      dayStartOffSet: jsDateToUtcOffsetSeconds(item.dayStartTime)
    }
    return this.createDepartmentGQL.mutate({input: parsedInput}).pipe(
      map((res: any) => this.toPayload(res.data.createDepartment, executionStart, this.paginationService.totalItems + 1)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  deleteRecord(id: string): Observable<Payload<boolean>> {
    const executionStart = new Date();
    return this.deleteDepartmentGQL.mutate({input: {guid: id}}).pipe(
      map((res: any) => this.toPayload(res.data.deleteDepartment, executionStart, this.paginationService.totalItems - 1)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  private toPayload(items: any, executionStart: Date, totalItems?: number, errorMsg?: string): Payload<any> {
    this.paginationService.totalItems = totalItems!;
    return {
      data: Array.isArray(items) ? items.map(i => this.parseItems(i)) : this.parseItems(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    }
  }

  private parseItems(item: any) {
    if (item) {
      const startTime = utcOffsetSecondsToJsDate(item.dayStartOffSet)
      return {
        ...item,
        __id: item.guid,
        dayStartTime: startTime,
        dayEndTime: DateTime.fromJSDate(startTime).plus({hour: 23, minute: 59}).toJSDate()
      }
    }
  }
}
