import {Injectable} from '@angular/core';
import {InputMaybe, SortEnumType} from "../../graphql/graphql";

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  currentPage: number = 0;
  pageSize: number = 10;
  totalItems: number = 0;
  order?: InputMaybe<SortEnumType> = SortEnumType.Asc;
  column?: string = '';

  constructor() {
  }
}
