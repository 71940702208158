<legacy-meta-cat-data-grid
    *ngIf="dataSource && metadata"
    [dataSource]="dataSource"
    [metadata]="metadata"
    [padding]="14"
    (clickedDataOnNoneEdit)="openSpanGrid($event)"
    [rowActionTemplate]="rowActions"
    [unselectRow]="unselectRow"
    (afterUpdated)="loadDataSource()"
    (afterRefresh)="loadDataSource()"
    (filterChange)="filterChange($event)"
>
</legacy-meta-cat-data-grid>
<div style="flex: 1"></div>
<mat-paginator
  *ngIf="dataSource"
  [length]="this.paginationService.totalItems"
  [pageSize]="this.paginationService.pageSize"
  [pageSizeOptions]="[10, 30, 50]"
  (page)="onPageChange($event)"
  aria-label="Select a page of logging results"
></mat-paginator>

<ng-template #rowActions let-data="data">
  <legacy-meta-cat-row-action
    [icon]="'edit'"
    (actionClicked)="editRow(data)"
  ></legacy-meta-cat-row-action>
</ng-template>
