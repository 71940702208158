<mat-dialog-content>
  <legacy-meta-cat-form
      *ngIf="dialogMetadata"
      [model]="model"
      [metadata]="dialogMetadata"
      (cancel)="cancel()"
      (submitForm)="submit($event)"
  >
  </legacy-meta-cat-form>
</mat-dialog-content>
