import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  AppStateService,
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  MakeDateProperty,
  MakeNumberProperty,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {ClientService} from "@core/services/client.service";
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {Department, SortEnumType} from "../../graphql/graphql";
import {SpanClientService} from "@core/services/span-client.service";
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import {ModelProperty} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-property";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PaginationService} from "@core/services/pagination.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {SpanGridComponent} from "@features/span-grid/span-grid.component";


interface FormModel {
  id: string;
  description: string;
  dayStartTime: Date;
}

interface ColumnSortEvent {
  value: string;
  title: string;
  type: any;
}

@Component({
  selector: 'app-department-grid',
  templateUrl: './department-grid.component.html',
  styleUrls: ['./department-grid.component.scss']
})
export class DepartmentGridComponent implements OnInit {
  metadata = new ModelMetadataBuilder()
    // properties
    .addProperties([
      MakeStringProperty('__id', 'GUID #'),
      MakeStringProperty('id', 'ID'),
      MakeStringProperty('description', 'Description'),
      MakeDateProperty('dayStartTime', 'Day Start Time', "time"),
      MakeDateProperty('dayEndTime', 'Day End Time', "time"),
      MakeNumberProperty('dayStartOffSet', 'Day Start Offset'),
    ])
    // grid configuration
    .setGridAddMode('dialog')
    .setGridEditMode('none')
    .forceShowGridDeleteRow(true)
    .addGridTitle('Department')
    .addGridColumn('id')
    .addGridColumn('description')
    .addGridColumn('dayStartTime')
    .addGridColumn('dayEndTime')
    .setFocusedRowEnabled(true)
    // form configuration
    .addFormTitle('Department')
    .addPropertyToFormRow('dayStartTime', 1).makeFieldRequired('dayStartTime')
    .addPropertyToFormRow('id', 2).makeFieldRequired('id')
    .addPropertyToFormRow('description', 2).makeFieldRequired('description')
    .create();

  dataSource?: Cat2CustomStore;
  unselectRow = false;

  @ViewChild(MatPaginator) paginator?: MatPaginator;

  constructor(
    private _client: ClientService,
    private _process: ClientProcessingService,
    private appState: AppStateService,
    private _spanClient: SpanClientService,
    private _dialog: MatDialog,
    public paginationService: PaginationService,
    private _bottomSheet: MatBottomSheet
  ) {
  }

  ngOnInit(): void {
    this.appState.showLoadingOverlay();
    setTimeout(() => {
      this.loadDataSource();
      this.paginationService.currentPage = 0;
      if (this.paginator) {
        this.paginator.pageSize = 10;
        this.paginator.pageIndex = this.paginationService.currentPage;
      }
      this.appState.hideLoadingOverlay();
    }, 1000);
  }

  onUnselectRow() {
    this.unselectRow = true;
    setTimeout(() => this.unselectRow = false);
  }

  openSpanGrid(data: Department) {
    this._spanClient.departmentName.next(data);
    const bottomSheetRef = this._bottomSheet.open(SpanGridComponent, {panelClass: 'customBottomSheet'});
    bottomSheetRef.afterDismissed().subscribe(() => this.onUnselectRow());
  }

  loadDataSource() {
    this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    );
  }

  filterChange(event: ColumnSortEvent) {
    this.paginationService.column = event.title;
    if (event.value && event.value != '') {
      switch (event.value) {
        case 'asc':
          this.paginationService.order = SortEnumType.Asc;
          break;
        case 'desc':
          this.paginationService.order = SortEnumType.Desc;
          break
      }
    } else {
      this.paginationService.order = undefined;
    }
    this.loadDataSource();
  }

  /**
   * Handles the page change event from the paginator.
   * @param event
   */
  onPageChange(event: { pageIndex: number; pageSize: number; }) {
    this.paginationService.currentPage = event.pageIndex;
    this.paginationService.pageSize = event.pageSize;
    this.loadDataSource();
  }

  editRow(data: any) {
    this._dialog.open(TimeAdjustmentDialogComponent, {
      width: '300px',
      data: data
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        data = {
          ...data,
          dayStartTime: result.dayStartTime ?? new Date(),
          description: result.description ?? "",
          id: result.id ?? ""
        }
        this.dataSource?.update(data.id, data).then(() => this.loadDataSource());
        this._spanClient.departmentName.next(data);
      }
      setTimeout(() => this.onUnselectRow(), 150);
    });
  }
}

@Component({
  selector: 'app-department-grid-dialog',
  templateUrl: './dialog-department-grid.component.html',
  styleUrls: ['./department-grid.component.scss']
})
export class TimeAdjustmentDialogComponent implements OnInit {
  dialogMetadata?: ModelMetadata;
  model?: FormModel;

  constructor(
    private _dialogRef: MatDialogRef<TimeAdjustmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
  }

  ngOnInit(): void {
    this.dialogMetadata = this.DialogMetadata();
    this.model = {
      dayStartTime: this.data.dayStartTime ?? new Date(),
      description: this.data.description ?? "",
      id: this.data.id ?? ""
    };
  }

  DialogMetadata(): ModelMetadata {
    return new ModelMetadataBuilder()
      .addProperties(this.FormProperties())
      .makeFieldRequired('id')
      .makeFieldRequired('description')
      .makeFieldRequired('dayStartTime')
      .addFormTitle('Edit Department')
      .addPropertyToFormRow('id', 1)
      .addPropertyToFormRow('description', 2)
      .addPropertyToFormRow('dayStartTime', 3)
      .create();
  }

  FormProperties(): ModelProperty[] {
    return [
      MakeStringProperty('id', 'ID'),
      MakeStringProperty('description', 'Description'),
      MakeDateProperty('dayStartTime', 'Day Start Time', "time")
    ];
  }

  submit(data: any) {
    this._dialogRef.close(data);
  }

  cancel() {
    this._dialogRef.close();
  }
}
